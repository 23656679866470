<template>
    <b-container>
        <b-row class="text-center mb-3">
            <b-col cols="12">
                <h1>Allaitement <span style="font-family: Times; font-size: 0.8em;">:</span> préparation et accompagnement</h1>
            </b-col>
        </b-row>
        <b-row align-v="center">
            <b-col xs="12" lg="6">
                <p>
                    <br />
                    Nous sommes naturellement programmées pour allaiter, pour
                    autant la mise en route de l'allaitement et la poursuite
                    d'un allaitement serein nécessite parfois un accompagnement.
                    L'allaitement est un choix intime et je serai à vos côtés
                    pour vous accompagner dans la réussite de cette belle
                    aventure.<br />
                    <br />
                    Vous avez besoin de faire le tri dans les conseils qui vous
                    ont été donnés ? Vous vous questionnez sur comment va se
                    passer la reprise du travail ?<br />
                    <br />
                    Je vous propose un atelier de préparation à l'allaitement ou
                    un accompagnement à votre allaitement déjà mis en place.<br />
                    <br />
                    Pour adapter au mieux, n'hésitez pas à prendre
                    <b-link to="/contact"><em>contact</em></b-link> avec moi.
                    <div id='price'>
                        <hr />
                        <p>
                            Tarifs<br />
                            Accompagnement - 50€*<br />
                            Préparation - 80€*<br />
                        </p>
                        <hr />
                        <em>
                            * des frais peuvent s'ajouter en fonction de la localisation de votre domicile.
                        </em>                                
                    </div>
                </p>
            </b-col>
            <b-col xs="12" lg="4">
                <non-clickable-img url="feeding.png" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Price from '../components/Price';
import NonClickableImg from '../components/NonClickableImg';

export default {
    name: 'Feeding',
    components: { Price, NonClickableImg },
};
</script>

<style scoped>
p {
    text-align: justify;
    text-justify: inter-word;
}

#price p {
    color: #fff;
    background-color: var(--secondary);

    font-weight: bold;

    text-align: center;
}

#price em {
    font-size: 12px;
}
</style>
